import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const prefix: any = 'custom'

const duotoneArrowRightToLine: IconDefinition = {
  icon: [448, 512, [], 'f340', ['M384 96l0 320c0 17.7 14.3 32 32 32s32-14.3 32-32l0-320c0-17.7-14.3-32-32-32s-32 14.3-32 32z', 'M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-128 128c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 288 32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l178.7 0-73.4-73.4c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l128 128z']],
  iconName: 'duotone-arrow-right-to-line' as any,
  prefix
}
const duotoneClone: IconDefinition = {
  icon: [512, 512, [], 'f24d', ['M288 448H64V224h96V160H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V352H288v96z', 'M160 288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224c-35.3 0-64 28.7-64 64V288z']],
  iconName: 'duotone-clone' as any,
  prefix
}
const duotoneCopy: IconDefinition = {
  icon: [448, 512, [], 'f0c5', ['M128 128H48c-26.5 0-48 21.5-48 48V464c0 26.5 21.5 48 48 48H272c26.5 0 48-21.5 48-48V416H256v32H64V192h64V128z', 'M160 48c0-26.5 21.5-48 48-48H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48z']],
  iconName: 'duotone-copy' as any,
  prefix
}
const duotoneFolderPlus: IconDefinition = {
  icon: [512, 512, [], 'f65e', ['M512 416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416zM232 376c0 13.3 10.7 24 24 24s24-10.7 24-24V312h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V200c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z', 'M232 312v64c0 13.3 10.7 24 24 24s24-10.7 24-24V312h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V200c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64z']],
  iconName: 'duotone-folder-plus' as any,
  prefix
}
const duotoneHeart: IconDefinition = {
  icon: [512, 512, [], 'f004', ['M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z', '']],
  iconName: 'duotone-heart' as any,
  prefix
}
const duotoneLineColumns: IconDefinition = {
  icon: [512, 512, [], 'f870', ['M496 288H304a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 128H304a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-256H304a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H304a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z', 'M208 288H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-384H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm0 128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z']],
  iconName: 'duotone-line-columns' as any,
  prefix
}
const duotoneMapPin: IconDefinition = {
  icon: [320, 512, [], 'f276', ['M128 284.4V480c0 17.7 14.3 32 32 32s32-14.3 32-32V284.4c-10.3 2.3-21 3.6-32 3.6s-21.7-1.2-32-3.6z', 'M160 288A144 144 0 1 0 160 0a144 144 0 1 0 0 288zM96 144c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-53 43-96 96-96c8.8 0 16 7.2 16 16s-7.2 16-16 16c-35.3 0-64 28.7-64 64z']],
  iconName: 'duotone-map-pin' as any,
  prefix
}
const duotoneMapSigns: IconDefinition = {
  icon: [512, 512, [], 'f277', ['M224 496a16 16 0 0 0 16 16h32a15.88 15.88 0 0 0 5.71-1.07l.52-.19.1-.05a16.08 16.08 0 0 0 5-3.38A15.91 15.91 0 0 0 288 496V384h-64zM272 0h-32a16 16 0 0 0-16 16v16h64V16a16 16 0 0 0-16-16zm-48 224h64v-32h-64z', 'M456 224H70.63A32 32 0 0 0 48 233.37L4.69 276.69a16 16 0 0 0 0 22.63L48 342.63A32 32 0 0 0 70.63 352H456a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24zm51.31-139.31L464 41.37A32 32 0 0 0 441.37 32H56a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24h385.37a32 32 0 0 0 22.63-9.37l43.31-43.31a16 16 0 0 0 0-22.63z']],
  iconName: 'duotone-map-signs' as any,
  prefix
}
const duotoneRandom: IconDefinition = {
  icon: [512, 512, [], 'f074', ['M505 359l-80-80c-15-15-41-4.47-41 17v40h-32l-52.78-56.55-53.33 57.14 70.55 75.6a12 12 0 0 0 8.77 3.81H384v40c0 21.46 26 32 41 17l80-80a24 24 0 0 0 0-34zM122.79 96H12a12 12 0 0 0-12 12v56a12 12 0 0 0 12 12h84l52.78 56.55 53.33-57.14-70.55-75.6a12 12 0 0 0-8.77-3.81z', 'M505 119a24 24 0 0 1 0 34l-80 80c-15 15-41 4.48-41-17v-40h-32L131.56 412.19a12 12 0 0 1-8.77 3.81H12a12 12 0 0 1-12-12v-56a12 12 0 0 1 12-12h84L316.44 99.81a12 12 0 0 1 8.78-3.81H384V56c0-21.44 25.94-32 41-17z']],
  iconName: 'duotone-random' as any,
  prefix
}
const duotoneShare: IconDefinition = {
  icon: [512, 512, [], 'f064', ['M288 288V128H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96z', 'M288 64c0-12.6 7.4-24.1 19-29.2s25-3 34.4 5.4l160 144c6.7 6.1 10.6 14.7 10.6 23.8s-3.8 17.7-10.6 23.8l-160 144c-9.4 8.5-22.9 10.6-34.4 5.4s-19-16.6-19-29.2V64z']],
  iconName: 'duotone-share' as any,
  prefix
}
const duotoneShuffle: IconDefinition = {
  icon: [512, 512, [], 'f074', ['M438.6 41.4c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6V96H352c-30.2 0-58.7 14.2-76.8 38.4L224 202.7 264 256l62.4-83.2c6-8.1 15.5-12.8 25.6-12.8h32v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c6-6 9.4-14.1 9.4-22.6s-3.4-16.6-9.4-22.6l-64-64zM224 309.3L184 256l-62.4 83.2c-6 8.1-15.5 12.8-25.6 12.8H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H96c30.2 0 58.7-14.2 76.8-38.4L224 309.3z', 'M352 416c-30.2 0-58.7-14.2-76.8-38.4L121.6 172.8c-6-8.1-15.5-12.8-25.6-12.8H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c30.2 0 58.7 14.2 76.8 38.4L326.4 339.2c6 8.1 15.5 12.8 25.6 12.8h32V320c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V416H352z']],
  iconName: 'duotone-shuffle' as any,
  prefix
}
const duotoneSortDown: IconDefinition = {
  icon: [320, 512, [], 'f0dd', ['M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zM109.3 160H210.7L160 109.3 109.3 160z', 'M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z']],
  iconName: 'duotone-sort-down' as any,
  prefix
}
const duotoneSortUp: IconDefinition = {
  icon: [320, 512, [], 'f0de', ['M137.4 470.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H32c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128zM109.3 352H210.7L160 402.7 109.3 352z', 'M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z']],
  iconName: 'duotone-sort-up' as any,
  prefix
}
const duotoneTrashCan: IconDefinition = {
  icon: [448, 512, [], 'f2ed', ['M416 96H32V448c0 35.3 28.7 64 64 64H352c35.3 0 64-28.7 64-64V96zM144 176V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z', 'M163.8 0c-12.1 0-23.2 6.8-28.6 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8z']],
  iconName: 'duotone-trash-can' as any,
  prefix
}
const regularLevelDown: IconDefinition = {
  icon: [320, 512, [], 'f149', 'M316.485 392l-116 116.485c-4.686 4.686-12.284 4.686-16.971 0L67.515 392c-4.686-4.686-4.686-12.284 0-16.971l22.312-22.312c4.808-4.808 12.646-4.665 17.275.315L164 414.996V56H44.024a11.996 11.996 0 0 1-8.485-3.515l-32-32C-4.021 12.926 1.333 0 12.024 0H196c13.255 0 24 10.745 24 24v390.996l56.899-61.963c4.629-4.98 12.467-5.123 17.275-.315l22.312 22.312c4.686 4.686 4.686 12.284-.001 16.97z'],
  iconName: 'regular-level-down' as any,
  prefix
}
const solidArrowToLeft: IconDefinition = {
  icon: [448, 512, [], 'f33e', 'M0 424V88c0-13.3 10.7-24 24-24h24c13.3 0 24 10.7 24 24v336c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zm280.5-66.4L214.9 292H424c13.3 0 24-10.7 24-24v-24c0-13.3-10.7-24-24-24H214.9l65.6-65.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L94.1 239c-9.4 9.4-9.4 24.6 0 33.9l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.3 9.4-24.5 0-33.8z'],
  iconName: 'solid-arrow-to-left' as any,
  prefix
}
const solidCodeMerge: IconDefinition = {
  icon: [384, 512, [], 'f387', 'M304 192c-38 0-69.8 26.5-77.9 62-23.9-3.5-58-12.9-83.9-37.6-16.6-15.9-27.9-36.5-33.7-61.6C138.6 143.3 160 114.1 160 80c0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-35.8-23.5-66.1-56-76.3V246.1c1.6 1.7 3.3 3.4 5 5 39.3 37.5 90.4 48.6 121.2 51.8 12.1 28.9 40.6 49.2 73.8 49.2 44.2 0 80-35.8 80-80S348.2 192 304 192zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-160c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16z'],
  iconName: 'solid-code-merge' as any,
  prefix
}
const solidFilter: IconDefinition = {
  icon: [512, 512, [], 'f0b0', 'M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z'],
  iconName: 'solid-filter' as any,
  prefix
}
const solidMagnifyingGlass: IconDefinition = {
  icon: [512, 512, [], 'f002', 'M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z'],
  iconName: 'solid-magnifying-glass' as any,
  prefix
}
const solidRepeat: IconDefinition = {
  icon: [512, 512, [], 'f363', 'M512 256c0 88.224-71.775 160-160 160H170.067l34.512 32.419c9.875 9.276 10.119 24.883.539 34.464l-10.775 10.775c-9.373 9.372-24.568 9.372-33.941 0l-92.686-92.686c-9.373-9.373-9.373-24.568 0-33.941l92.686-92.686c9.373-9.373 24.568-9.373 33.941 0l10.775 10.775c9.581 9.581 9.337 25.187-.539 34.464L170.067 352H352c52.935 0 96-43.065 96-96 0-13.958-2.996-27.228-8.376-39.204-4.061-9.039-2.284-19.626 4.723-26.633l12.183-12.183c11.499-11.499 30.965-8.526 38.312 5.982C505.814 205.624 512 230.103 512 256zM72.376 295.204C66.996 283.228 64 269.958 64 256c0-52.935 43.065-96 96-96h181.933l-34.512 32.419c-9.875 9.276-10.119 24.883-.539 34.464l10.775 10.775c9.373 9.372 24.568 9.372 33.941 0l92.686-92.686c9.373-9.373 9.373-24.568 0-33.941l-92.686-92.686c-9.373-9.373-24.568-9.373-33.941 0L306.882 29.12c-9.581 9.581-9.337 25.187.539 34.464L341.933 96H160C71.775 96 0 167.776 0 256c0 25.897 6.186 50.376 17.157 72.039 7.347 14.508 26.813 17.481 38.312 5.982l12.183-12.183c7.008-7.008 8.786-17.595 4.724-26.634z'],
  iconName: 'solid-repeat' as any,
  prefix
}
const solidSort: IconDefinition = {
  icon: [320, 512, [], 'f0dc', 'M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z'],
  iconName: 'solid-sort' as any,
  prefix
}
const solidXmark: IconDefinition = {
  icon: [384, 512, [], 'f00d', 'M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z'],
  iconName: 'solid-xmark' as any,
  prefix
}

export default [
  duotoneArrowRightToLine,
  duotoneClone,
  duotoneCopy,
  duotoneFolderPlus,
  duotoneHeart,
  duotoneLineColumns,
  duotoneMapPin,
  duotoneMapSigns,
  duotoneRandom,
  duotoneShare,
  duotoneShuffle,
  duotoneSortDown,
  duotoneSortUp,
  duotoneTrashCan,
  regularLevelDown,
  solidArrowToLeft,
  solidCodeMerge,
  solidFilter,
  solidMagnifyingGlass,
  solidRepeat,
  solidSort,
  solidXmark
]
