import * as fasBackward from '@fortawesome/free-solid-svg-icons/faBackward'
import * as fasCaretLeft from '@fortawesome/free-solid-svg-icons/faCaretLeft'
import * as fasCaretRight from '@fortawesome/free-solid-svg-icons/faCaretRight'
import * as fasCheck from '@fortawesome/free-solid-svg-icons/faCheck'
import * as fasCopy from '@fortawesome/free-solid-svg-icons/faCopy'
import * as fasEllipsisH from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import * as fasExternalLinkAlt from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import * as fasPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as fasTrashAlt from '@fortawesome/free-solid-svg-icons/faTrashAlt'

export default [
  fasBackward.definition,
  fasCaretLeft.definition,
  fasCaretRight.definition,
  fasCheck.definition,
  fasCopy.definition,
  fasEllipsisH.definition,
  fasExternalLinkAlt.definition,
  fasPlus.definition,
  fasTrashAlt.definition
] as const
