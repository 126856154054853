import { Flow, FlowPartial, FlowRequired, FlowsService, FlowStep, FlowThumbnail, SocketClientFlowsSubscribeBody } from '@icepanel/platform-api-client'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import getFirestoreId from '@/helpers/firestore-id'
import randomId from '@/helpers/random-id'
import * as sort from '@/helpers/sort'
import Status from '@/helpers/status'
import { ServerEvents, Socket } from '@/plugins/socket'

export interface IFlowModule {
  flowsCurrent: Record<string, Flow>
  flowsCache: Record<string, Flow> | null
  flowsCommit: Record<string, number>

  flowsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>

  flowsListStatus: Status

  flowThumbnails: Record<string, FlowThumbnail>
}

const name = 'flow'

@Module({
  name,
  namespaced: true
})
export class FlowModule extends VuexModule implements IFlowModule {
  static namespace = name

  flowsCurrent: Record<string, Flow> = {}
  flowsCache: Record<string, Flow> | null = null
  flowsCommit: Record<string, number> = {}

  flowsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>()

  flowsListStatus = new Status()

  flowThumbnails: Record<string, FlowThumbnail> = {}

  get generateFlowId () {
    return () => getFirestoreId('flow')
  }

  get generateFlow () {
    return (landscapeId: string, versionId: string, props: FlowRequired, id = this.generateFlowId()): { flow: Flow, flowUpsert: FlowRequired & FlowPartial } => {
      const commit = typeof this.flowsCommit[id] === 'number' ? this.flowsCommit[id] + 1 : props.commit || 0
      const defaultHandleId = randomId()
      return {
        flow: {
          handleId: defaultHandleId,
          index: 0,
          labels: {},
          showAllSteps: false,
          showConnectionNames: false,
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          id,
          landscapeId,
          pinned: false,
          stats: {
            edits: {
              all: {
                count: 0,
                users: {}
              },
              day: {},
              month: {},
              week: {}
            },
            views: {
              all: {
                count: 0,
                users: {}
              },
              day: {},
              month: {},
              week: {}
            }
          },
          steps: sort.flowSteps(props.steps || {}),
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        flowUpsert: {
          handleId: defaultHandleId,
          labels: {},
          showConnectionNames: false,
          steps: {},
          ...props,
          commit
        }
      }
    }
  }

  get generateFlowCommit () {
    return (id: string, props: Omit<FlowPartial, 'commit'>): { flow: Flow, flowUpdate: FlowPartial } => {
      const flow = structuredClone(this.flows[id])
      if (flow) {
        const commit = flow.commit + 1
        flow.pinnedAt = props.pinned ? new Date().toISOString() : undefined
        flow.updatedAt = new Date().toISOString()
        flow.updatedBy = 'user'
        flow.updatedById = this.context.rootState.user.user?.id || ''

        if (props.steps?.$replace) {
          flow.steps = props.steps.$replace
        } else {
          if (props.steps?.$add) {
            const connectionConflictId = Object.keys(props.steps.$add).find(o => flow.steps[o])
            if (connectionConflictId) {
              throw new Error(`Step ${connectionConflictId} already exists`)
            }

            // new steps should get added first so they are sorted into the right order
            flow.steps = {
              ...props.steps.$add,
              ...flow.steps
            }
          }

          if (props.steps?.$update) {
            const connectionConflictId = Object.keys(props.steps.$update).find(o => !flow.steps[o])
            if (connectionConflictId) {
              throw new Error(`Step ${connectionConflictId} does not exist`)
            }

            flow.steps = {
              ...flow.steps,
              ...Object.entries(props.steps.$update).reduce<Record<string, FlowStep>>((p, [id, props]) => ({
                ...p,
                [id]: {
                  ...flow.steps[id],
                  ...props
                }
              }), {})
            }
          }

          if (props.steps?.$remove) {
            const connectionConflictId = props.steps.$remove.find(o => !flow.steps[o])
            if (connectionConflictId) {
              throw new Error(`Step ${connectionConflictId} does not exist`)
            }

            props.steps.$remove.forEach(o => {
              delete flow.steps[o]
            })
          }
        }

        return {
          flow: {
            ...flow,
            ...props,
            commit,
            steps: sort.flowSteps(flow.steps)
          },
          flowUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find flow ${id}`)
      }
    }
  }

  get flows () {
    return this.flowsCache || this.flowsCurrent
  }

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  @Mutation
  setFlows (flows: Flow[] | Record<string, Flow>) {
    this.flowsCurrent = Object
      .values(flows)
      .reduce<IFlowModule['flowsCurrent']>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
    this.flowsCommit = Object.values(this.flowsCurrent).reduce<IFlowModule['flowsCommit']>((p, c) => ({
      ...p,
      [c.id]: c.commit
    }), {})
  }

  @Mutation
  setFlowsCache (flowsCache: Record<string, Flow> | null) {
    this.flowsCache = flowsCache
  }

  @Mutation
  setFlow (flow: Flow) {
    Vue.set(this.flowsCurrent, flow.id, flow)
    Vue.set(this.flowsCommit, flow.id, flow.commit)
  }

  @Mutation
  setFlowVersion (flow: Flow) {
    if (
      this.flowsCommit[flow.id] === undefined ||
      flow.commit > this.flowsCommit[flow.id] ||
      (flow.commit === this.flowsCommit[flow.id] && this.flowsCurrent[flow.id] && flow.version > this.flowsCurrent[flow.id].version)
    ) {
      Vue.set(this.flowsCurrent, flow.id, flow)
      Vue.set(this.flowsCommit, flow.id, flow.commit)
    }
  }

  @Mutation
  removeFlow (flow: string | Pick<Flow, 'id' | 'commit'>) {
    if (typeof flow === 'string') {
      Vue.delete(this.flowsCurrent, flow)
    } else if (flow.commit >= this.flowsCommit[flow.id]) {
      Vue.delete(this.flowsCurrent, flow.id)
      this.flowsCommit[flow.id] = flow.commit
    }
  }

  @Mutation
  setFlowsSubscriptionStatus (...params: Parameters<typeof this.flowsSubscriptionStatus.set>) {
    this.flowsSubscriptionStatus.set(...params)
  }

  @Mutation
  flowsUnsubscribe () {
    this.flowsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.flowsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.flowsSubscriptionStatus.set(Status.idle())
  }

  @Mutation
  setFlowsListStatus (...params: Parameters<typeof this.flowsListStatus.set>) {
    this.flowsListStatus.set(...params)
  }

  @Mutation
  setFlowThumbnails (thumbnails: Record<string, FlowThumbnail>) {
    this.flowThumbnails = {
      ...this.flowThumbnails,
      ...thumbnails
    }
  }

  @Mutation
  resetFlowThumbnails () {
    this.flowThumbnails = {}
  }

  @Action({ rawError: true })
  async flowsSubscribe (body: SocketClientFlowsSubscribeBody & { reconnect: boolean }) {
    this.flowsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.flowsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setFlowsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: Flow[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('flows-subscribe', {
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const flowInitialValueListener: ServerEvents['flow-initial-value'] = ({ flows, finalValue, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...flows)

              if (finalValue) {
                this.context.commit('setFlows', initialValue)

                this.context.commit('setFlowsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const flowAddedListener: ServerEvents['flow-added'] = ({ flow, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setFlowVersion', flow)
            }
            if (flow.createdById && flow.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const flowModifiedListener: ServerEvents['flow-modified'] = ({ flow, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setFlowVersion', flow)
            }
            if (flow.updatedById && flow.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const flowRemovedListener: ServerEvents['flow-removed'] = ({ flow, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeFlow', flow)
            }
            if (flow.deletedById && flow.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('flow-initial-value', flowInitialValueListener)
            this.socket.off('flow-added', flowAddedListener)
            this.socket.off('flow-modified', flowModifiedListener)
            this.socket.off('flow-removed', flowRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('flows-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('flows unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('flow-initial-value', flowInitialValueListener)
          this.socket.on('flow-added', flowAddedListener)
          this.socket.on('flow-modified', flowModifiedListener)
          this.socket.on('flow-removed', flowRemovedListener)

          this.context.commit('setFlowsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Flow subscription not provided'
          this.context.commit('setFlowsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async flowsList ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    try {
      this.context.commit('setFlowsListStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { flows } = await FlowsService.flowsList(authorization, landscapeId, versionId)
      this.context.commit('setFlows', flows)

      this.context.commit('setFlowsListStatus', Status.success())

      return flows
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setFlowsListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async flowView ({ landscapeId, versionId, flowId }: { landscapeId: string, versionId: string, flowId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await FlowsService.flowView(authorization, landscapeId, versionId, flowId)
  }

  @Action({ rawError: true })
  async flowCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: FlowRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { flow } = await FlowsService.flowCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setFlowVersion', flow)
    return flow
  }

  @Action({ rawError: true })
  async flowUpsert ({ landscapeId, versionId, flowId, props }: { landscapeId: string, versionId: string, flowId: string, props: FlowRequired & FlowPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { flow } = await FlowsService.flowUpsert(authorization, landscapeId, versionId, flowId, props)
    this.context.commit('setFlowVersion', flow)
    return flow
  }

  @Action({ rawError: true })
  async flowUpdate ({ landscapeId, versionId, flowId, props }: { landscapeId: string, versionId: string, flowId: string, props: FlowPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { flow } = await FlowsService.flowUpdate(authorization, landscapeId, versionId, flowId, props)
    this.context.commit('setFlowVersion', flow)
    return flow
  }

  @Action({ rawError: true })
  async flowDelete ({ landscapeId, versionId, flowId }: { landscapeId: string, versionId: string, flowId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await FlowsService.flowDelete(authorization, landscapeId, versionId, flowId)
    this.context.commit('removeFlow', { commit, id: flowId })
  }

  @Action({ rawError: true })
  async flowExportText ({ landscapeId, versionId, flowId }: { landscapeId: string, versionId: string, flowId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const text = await FlowsService.flowExportText(authorization, landscapeId, versionId, flowId)
    return text
  }

  @Action({ rawError: true })
  async flowExportCode ({ landscapeId, versionId, flowId }: { landscapeId: string, versionId: string, flowId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const code = await FlowsService.flowExportCode(authorization, landscapeId, versionId, flowId)
    return code
  }

  @Action({ rawError: true })
  async flowThumbnailGet ({ landscapeId, versionId, flowId }: { landscapeId: string, versionId: string, flowId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { thumbnail } = await FlowsService.flowThumbnailGet(authorization, landscapeId, versionId, flowId)

    this.context.commit('setFlowThumbnails', {
      [flowId]: thumbnail
    })

    return thumbnail
  }
}
